import React from 'react';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import LeagueStrength from '../components/LeagueStrength';
import PetSolution from '../components/PetSolution';
import Partner from '../components/Partner';
import FlexPicture from '../components/FlexPicture';
// import Register from '../components/register/Register';
import RegisterGuide from '../components/register/RegisterGuide';

const League = () => {
  const title = '魔鬼鱼官网';
  return (
    <Layout bodyClass="page-home">
      <SEO title={title} />
      <Helmet>
        <meta
          name="description"
          content="Small Business Theme."
        />
      </Helmet>

      <div className="league-wrapper">
        <div className="league">
          <div className="league-container">
            <div className="league-banner-container">
              <a href="#register">
                <FlexPicture
                  icon="league/商户加盟banner"
                  name="banner"
                  className="banner banner-height"
                />
              </a>
            </div>
            <div className="banner">
              <LeagueStrength />
            </div>
            <div className="banner banner-solution">
              <PetSolution />
            </div>
            <div className="banner" id="test">
              <Partner />
            </div>
            <div id="register" />
            <div className="league-register-container">
              {/* <Register /> */}
              <RegisterGuide />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default League;
