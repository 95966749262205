import React from 'react';

class RegisterGuide extends React.Component {
  render() {
    return (
      <div class="guide-page-container">
        <img src="https://img3.petkit.cn/images/d6c2717672d147398ef812a7a6c3a73f" alt="" />
      </div>
    );
  }
}

export default RegisterGuide;
