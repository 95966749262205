import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
const LeagueStrength = () => {
  const data = useStaticQuery(graphql`
    query LeagueStrengthQuery {
      leagueJson(supplier: {}) {
        sku {
          id
          name
          icon
          description
        }
        brand {
          id
          certificate
          icon
          description
          name
        }
        supplier {
          id
          name
          icon
          description
        }
      }
    }
  `);
  const { sku, supplier, brand } = data.leagueJson;
  return (
    <div className="league-strength">
      <div className="container">
        <div className="strength-row">
          <h2 className="strength-h2">{sku.name}</h2>
          <div className="strength-wrapper">
            <div className="strength-content">
              <div className="strength-text">
                {sku.description.map((des, idx) => (
                  <span key={`${des + idx}`}>
                    { des}
                    <br />
                  </span>
                ))}
              </div>
              <picture>
                <source
                  srcSet={`/images/2x/league/${sku.icon}@2x.png`}
                  media="(min-resolution: 2dppx)"
                />
                <source
                  srcSet={`/images/3x/league/${sku.icon}@3x.png`}
                  media="(min-resolution: 3dppx)"
                />
                <img
                  className={`strength-img strength-img-${sku.id}`}
                  src={`/images/1x/league/${sku.icon}@1x.png`}
                  title={sku.name}
                  alt={sku.name}
                />
              </picture>
            </div>

          </div>
        </div>
        <div className="strength-row">
          <h2 className="strength-h2">{supplier.name}</h2>
          <div className="strength-wrapper">
            <div className="strength-content">
              <picture>
                <source
                  srcSet={`/images/2x/league/${supplier.icon}@2x.png`}
                  media="(min-resolution: 2dppx)"
                />
                <source
                  srcSet={`/images/3x/league/${supplier.icon}@3x.png`}
                  media="(min-resolution: 3dppx)"
                />
                <img
                  className={`strength-img strength-img-${supplier.id}`}
                  src={`/images/1x/league/${supplier.icon}@1x.png`}
                  title={supplier.name}
                  alt={supplier.name}
                />
              </picture>
              <div className="strength-text">
                {supplier.description.map((des, idx) => (
                  <span key={`${des + idx}`}>
                    { des}
                    <br />
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="strength-row">
          <h2 className="strength-h2">{brand.name}</h2>
          <div className="strength-wrapper">
            <div className="strength-content auth-content">
              <div className="strength-text">
                {brand.description.map((des, idx) => (
                  <span key={`${des + idx}`}>
                    {des}
                    <br />
                  </span>
                ))}
              </div>
              <div className="auth-pic">
                <picture>
                  <source
                    srcSet={`/images/2x/league/${brand.certificate}@2x.png`}
                    media="(min-resolution: 2dppx)"
                  />
                  <source
                    srcSet={`/images/2x/league/${brand.certificate}@2x.png`}
                    media="(min-resolution: 3dppx)"
                  />
                  <img
                    className="auth-img"
                    src={`/images/2x/league/${brand.certificate}@2x.png`}
                    title={brand.name}
                    alt={brand.name}
                  />
                </picture>
                <picture>
                  <source
                    srcSet={`/images/2x/league/${brand.icon}@2x.png`}
                    media="(min-resolution: 2dppx)"
                  />
                  <source
                    srcSet={`/images/3x/league/${brand.icon}@3x.png`}
                    media="(min-resolution: 3dppx)"
                  />
                  <img
                    className={`strength-img strength-img-${brand.id}`}
                    src={`/images/1x/league/${brand.icon}@1x.png`}
                    title={supplier.name}
                    alt={supplier.name}
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeagueStrength;
